import Vue from 'vue'
import VueRouter from 'vue-router'

import {getCurrentUserId} from "@/service/firebase/user";

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('@/views/Home'),
    name: 'Home'
  },
  {
    path: '/',
    component: () => import('@/views/LoginPage/Index'),
    children: [{
      name: 'LoginPage',
      path: '/login',
      component: () => import('@/views/LoginPage/Login')
    },
      {
        name: 'ErrorPage',
        path: '/error',
        component: () => import('@/views/LoginPage/ErrorPage')
      },
      {
        name: 'StorePage',
        path: '/store',
        component: () => import('@/views/LoginPage/StorePage')
      }]
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'LoginPage') {
    if (!getCurrentUserId()) {
      next({ name: 'LoginPage' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
