<template>
    <v-app>
      <router-view/>

    </v-app>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
.mobilText {
    font-size: 24px;
    height: 40px;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-content: center;*/
}

div {
    font-family: 'Noto Sans SC', 'Noto Sans', sans-serif !important;
}
</style>

