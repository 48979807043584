import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginStateReady: false,
  },
  mutations: {
    loginStateReady (state) {
      state.loginStateReady = true
    },

  },
  getters: {
  },
  actions: {},
  modules: {}
})
